angular.module 'shared'
  .factory 'FreeTrialRemindMessageText',  ->
    get: (planName, href) ->
      "<strong>Reminder:</strong> You are currently on a 15 Day free trial with Clubworx with unlimited access to all features! To continue using this feature after your trial ends, make sure you sign up to the <strong>#{planName} plan</strong>. Visit the <a href=#{href}>My Subscription page</a> any time for more information on pricing plans."

    getMessage: (planName) ->
      "You are currently on a 15 Day free trial with Clubworx with unlimited access to all features! This is just a reminder that this feature is only available when you sign up to the <strong>#{planName} plan</strong>#{if planName isnt 'Established' then ' or higher.' else '.'}"

    getMessageForBillingPage: (planName) ->
      "You are currently on your <strong>FREE</strong> 15-day trial. This free trial gives you access to all features available on the <strong>#{planName}</strong> Plan (see below) so you can test these features out at no cost. Enjoy!"
